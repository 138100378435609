/*
Color Palette

primary: ;
secondary: ;

green: ;
red: ;
yellow: ;
blue: ;

*/

$primary-color: #fff;
$secondary-color: #000;

.app{
	display:flex;
	flex-direction: column;
}

.app-wrapper{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	margin-top: 50px;

}

.card-wrapper{
	display: grid;
	grid-template-columns: repeat(2, 1fr);
}

.highscore{
	font-size: 24px;
	text-align: center;
}