.score-button{
	position: absolute;

	background-color: #fff;
	color: #000;
	font-size: 36px;
	width: 120px;
	height: 120px;
	border-radius: 50%;

	text-align: center;
	vertical-align: middle;
	line-height: 120px;

	cursor: pointer;
}